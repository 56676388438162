import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Helmet } from 'react-helmet'
import Layout from './Layout/Layout'
import Home from '../pages/Home'
import Movies from '../pages/Movies'
import MovieDetail from '../pages/MovieDetail'
import Blog from '../pages/Blog'
import ContactUs from '../pages/ContactUs'
import AboutUs from '../pages/AboutUs'
import Faq from '../pages/Faq'
import Celebrity from '../pages/Celebrity'
import NotFound from '../pages/NotFound'
import Crowdfunding from '../pages/Crowdfunding'
import CrowdfundingDetail from '../pages/CrowdfundingDetail'
import BlogDetail from '../pages/BlogDetail'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import { useTranslation } from 'react-i18next'
import Loading from '../pages/Loading'
import Loading2 from '../pages/Loading2'
import Account from '../pages/Account'
import ResetPass from '../pages/ResetPass'
import Expire from '../pages/Expire'

import Collections from '../pages/Collections'
import Subscription from './AccountComponent/SettingLinks/Subscription/Subscription'
import { Purchased } from './AccountComponent/SettingLinks/Purchased/Purchased'
import { WatchLater } from './AccountComponent/SettingLinks/WatchLater/WatchLater'
import { ViewsHistory } from './AccountComponent/SettingLinks/ViewHistory/ViewsHistory'
import { SettingsPage } from './AccountComponent/SettingLinks/SettingsPage/Settings'

const MainRoutes: React.FC = () => {
	const { t, i18n } = useTranslation('azcinemaonline')

	return (
		<>
			<Helmet>
				<body className="main-app" />
				<html className="main-app" />
			</Helmet>
			<Layout>
				<React.Suspense fallback={<Loading2 />}>
					<Switch>
						{/* <Route exact path={'/' + t('routes.celebrity')} component={Celebrity} /> */}
						<Route exact path={'/' + t('routes.blog') + '/:id(\\d+)'} component={BlogDetail} />
						<Route exact path={'/' + t('routes.crowdfundingdetail') + '/:id(\\d+)'} component={CrowdfundingDetail} />
						<Route exact path={'/' + t('routes.crowdfunding')} component={Crowdfunding} />
						<Route exact path={'/' + t('routes.reset-password')} component={ResetPass} />
						<Route exact path={'/' + t('routes.collections')} component={Collections} />
						<Route exact path={'/' + t('routes.account')} component={Account} />
						<Route exact path={'/expire'} component={Expire} />
						<Route
							exact
							path={'/' + t('routes.account') + '/' + t('routes.subscription')}
							component={Subscription}
						/>
						<Route
							exact
							path={'/' + t('routes.account') + '/' + t('routes.purchased')}
							component={Purchased}
						/>
						<Route
							exact
							path={'/' + t('routes.account') + '/' + t('routes.watchLater')}
							component={WatchLater}
						/>
						<Route
							exact
							path={'/' + t('routes.account') + '/' + t('routes.views')}
							component={ViewsHistory}
						/>
						<Route
							exact
							path={'/' + t('routes.account') + '/' + t('routes.settingsPage')}
							component={SettingsPage}
						/>
						<Route exact path={'/' + t('routes.privacy')} component={Privacy} />
						<Route exact path={'/' + t('routes.terms')} component={Terms} />
						<Route exact path={'/' + t('routes.aboutus')} component={AboutUs} />
						<Route exact path={'/' + t('routes.faq')} component={Faq} />
						<Route exact path={'/' + t('routes.contactus')} component={ContactUs} />
						<Redirect
							from={'/' + t('routes.blogOld') + '/:id(\\d+)'}
							to={'/' + t('routes.blog') + '/:id(\\d+)'}
						/>
						<Redirect
							from={'/' + t('routes.blogOld') + '/:category?'}
							to={'/' + t('routes.blog') + '/:category?'}
						/>
						<Route exact path={'/' + t('routes.blog') + '/:id(\\d+)'} component={BlogDetail} />
						<Route exact path={'/' + t('routes.blog') + '/:category?'} component={Blog} />
						<Route exact path={'/' + t('routes.movies') + '/:id(\\d+)'} component={MovieDetail} />
						<Route
							path={'/' + t('routes.movies') + '/:selectedGenre?' + '/:selectedCountry?'}
							component={Movies}
						/>
						<Route exact path="/" component={Home} />
						<Route exact path="/404" component={NotFound} />
						<Route exact component={NotFound} />
					</Switch>
				</React.Suspense>
			</Layout>
		</>
	)
}
export default MainRoutes
