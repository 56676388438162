import React, { useEffect, useState } from 'react'
import '../../AccountComponent.scss'
import { Profile } from '../../../../store/models/profile'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SubscriptionPeriod from './SubscriptionPeriod'
import { fetchGet, fetchPost, fetchPostPayment, fetchPostText } from '../../../../store/rest/fetch'
import { AppState } from '../../../../store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { SETTINGS } from '../../../../store/types'
import { toast } from 'react-toastify'
import { getProfile } from '../../../../store/actions/profile.actions'
import { CloseButton } from '../../../CloseButton/CloseButton'
import i18n from '../../../../i18n'
import { Helmet } from 'react-helmet'
import { Modal } from 'reactstrap'
interface SubscriptionProps {
	profile: Profile | null
}

const Subscription: React.FC<SubscriptionProps> = ({ profile }) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Subscription" : lang === 'ru' ? "Подписки" : "Abunəlik"
	const { t } = useTranslation(['azcinemaonline'])
	const [subscriptionStatus, setSubscriptionStatus] = useState('')
	const [promoStatus, setPromoStatus] = useState('')
	const [promoCode, setPromoCode] = useState('')
	const [promoCompany, setPromoCompany] = useState('')
	const [promoExpire, setPromoExpire] = useState('')
	const [promoMounth, setPromoMounth] = useState(0)
	const [promoMsg, setPromoMsg] = useState('')
	const [modal, setModal] = useState(false)
	const router = useHistory()
	const [isFetching, setIsFetching] = useState(false)
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const subscribe = (duration: number) => {
		if (!isAuthenticated) {
			dispatch({ type: SETTINGS.LOGIN_POPUP, payload: true })
			return
		}
		var windowReference = window.open()
		setIsFetching(true)
		fetchPostText(
			{
				type: 'subscription',
				duration: duration,
			},
			'payment'
		)
			.then((res: string) => {
				let trans_id_index = res.indexOf('trans_id=')
				let trans_id = res.slice(trans_id_index + 9)
				localStorage.setItem('payment/pasha', JSON.stringify({ trans_id, url: router.location.pathname }))

				if (windowReference) {
					windowReference.location.href = res
					getTransStatus(trans_id, 0)
				}
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
				windowReference?.close()
			})
	}
	const getTransStatus = (trans_id: string, iteration: number) => {
		fetchPostPayment(
			{
				trans_id,
				is_client: true,
			},
			'payment/pasha'
		)
			.then((res) => {
				if (res.status === 'PENDING' && iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				} else if (res.status === 'SUCCESS') {
					setIsFetching(false)
					toast.success(res.data.ErrorMessage, { closeButton: CloseButton })
					dispatch(getProfile)
				} else if (res.status === 'FAILED') {
					setIsFetching(false)
					toast.error(res.data.ErrorMessage, { closeButton: CloseButton })
					dispatch(getProfile)
				}
			})
			.catch((err) => {
				console.log(err)
				if (iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				}
			})
	}
	const getCertificate = () => {
		setIsFetching(true)
		fetchPost({code: promoCode}, 'profile/certificate/use')
		.then((res) => {
			console.log(res);
			setPromoMsg(res.data.msg)
			toast.success(res.data.msg, { closeButton: CloseButton })
			setIsFetching(false)
			setModal(false)
		})
		.catch((err) => {
			console.log(err);
			toast.error(err.data.msg, { closeButton: CloseButton })
			setIsFetching(false)
		})
	}
	useEffect(() => {
		fetchGet('profile/subscription')
			.then((res) => {
				console.log(res);
				if (res.data.subscription.type === 'subscription') {
					setSubscriptionStatus(res.data.subscription.type)
				}
				if (res.data.subscription.type === 'certificate') {
					setPromoStatus(res.data.subscription.type)
					setPromoCompany(res.data.collection.company)
					setPromoExpire(new Date(res.data.subscription.expires_at).toLocaleDateString())
					setPromoMounth(res.data.collection.mounth_count)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	return (
		<>
			
			<Helmet title={title}/>
			<div className="account">
				<div className="container-fluid">
					<div className="row">
						<div
							className="backToAccaunt"
							onClick={() => {
								router.push('/' + t('routes.account'))
							}}
						>
							<i className="fas fa-chevron-left"></i>
							{t('account.backButton')}
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<span className="subCategory">{t('account.activeSub')}</span>
						</div>
						{subscriptionStatus != 'subscription' ? (
							<div className="col-12" style={{ fontSize: '18px' }}>
								{t('account.noActiveSub')}
							</div>
						) : (
							<div className="col-12 mt-4 subscriptionSection">
								<div className="row">
									<div className="col-12 col-sm-6 col-lg-3 d-flex flex-row subscriptionPeriodInner">
										<div className="subscriptionPeriod subActive">
											<div className="subscriptionPeriodMonth subActive">1</div>
											<div className="subscriptionPeriodPriceBlock">
												<div className="subscriptionPeriodPriceBlockPrice">4.99 &#8380;</div>
												<div className="subscriptionPeriodPriceBlockDuration">
													{t('account.perMonth')}
												</div>
											</div>
											<div className="subscriptionPeriodDescription ActiveTill">
												{new Date(subscriptionStatus).getTime() - new Date().getTime() > 0
													? t('account.subActiveTill')
													: t('account.subActiveTill')}
												{new Date(subscriptionStatus).toLocaleDateString()}
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>


				<div className="container-fluid mt-4">
					<div className="row">
						<div className="col-12">
							<span className="subCategory">{t('account.activePromo')}</span>
						</div>
						{promoStatus != 'certificate' ? (
							<div className="col-12" style={{ fontSize: '18px' }}>
								{t('account.noActivePromo')}
							</div>
						) : (
							<div className="m-2 activeSubscriptionPeriod1 col-12 col-sm-6">
								<div className="subscriptionPeriodMonth">{promoMounth}</div>
								<span className="subCategory1 w-100">{promoCompany}</span>
								<span className="promoContent w-100 orange-text">{t('account.subActiveTill')}{promoExpire}</span>
							</div>
						)}
					</div>
				</div>


				<div className="container-fluid mt-4 subscriptionSection">
					<span className="subCategory">{t('account.otherSub')}</span>
					<div className="row">
						<SubscriptionPeriod
							subsPeriod={{
								oneMonth: true,
								duration: 1,
								pricePerMoth: 4.99,
							}}
							subscribe={subscribe}
						/>
						<SubscriptionPeriod
							subsPeriod={{
								oneMonth: false,
								duration: 3,
								pricePerMoth: 3.99,
								offer: 16,
								totalPrice: 11.99,
							}}
							subscribe={subscribe}
						/>
						<SubscriptionPeriod
							subsPeriod={{
								oneMonth: false,
								duration: 6,
								pricePerMoth: 3.66,
								offer: 24,
								totalPrice: 21.99,
							}}
							subscribe={subscribe}
						/>
						<SubscriptionPeriod
							subsPeriod={{
								oneMonth: false,
								duration: 12,
								pricePerMoth: 3.17,
								offer: 36,
								totalPrice: 37.99,
							}}
							subscribe={subscribe}
						/>
					</div>
				</div>
				{/* <input type="text" placeholder={t('account.promoplace')} className='promoInput my-2 col-12 col-sm-6 col-md-4 col-lg-3'/>
				<br/>
				<button className="promoBtn">Send</button> */}
				<div  className="promoActivating container-fluid mt-3 mb-2 p-0" style={{transform: "scale(1.007)"}}>
					{/* <span className="subCategory">{t('account.promo')}</span> */}
					<button type="button" className="my-2 subscriptionPeriod1 col-12 col-sm-6" onClick={() => {
						if (!isAuthenticated) {
							dispatch({ type: SETTINGS.LOGIN_POPUP, payload: true })
							return
						} else {
							setModal(true)
						}
					}}>
						<span className="subCategory1 w-100">{t('account.activePromoTitle')}</span>
						<span className="promoContent w-100">{t('account.promoContent')}</span>
						<div className="buttonContainer w-100">
							<button className="activatePromo">{t('account.activateBtn')}</button>
						</div>
					</button>
				</div>
			</div>
			{isFetching && (
				<div className="subscriptionSectionLoading">
					<div className="subscriptionSectionLoadingSpinner">
						<div className="spinner-border" role="status"></div>
					</div>
				</div>
			)}
			<Modal 
				className='promoModal'
				isOpen={modal}
			>
				<div className="promoModal-dialog">
					<div className="modal-content promoModal-content">
						<div className="modal-header promoModal-header">
							<h5 className="modal-title promoModal-title" id="promoModalLabel">{t('account.modalTitle')}</h5>
							<button type="button" className="close" onClick={() => setModal(false)}>
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body promoModal-body">
							<form onSubmit={(e) => {
											e.preventDefault();
											setIsFetching(true)
										}} >
								<label> {t('account.promoplace')}: </label>
								<input type="text" className="promoInput w-100" onSubmit={(e) => {e.preventDefault()}} onChange={(e) => {setPromoCode(e.target.value)}} />
								
								<button type='submit' onClick={getCertificate} className="promoBtn mt-3">{t('account.activateBtn')}</button>
							</form>
						</div>
					</div>
				</div>

			</Modal>
		</>
	)
}

export default Subscription
