import React, { useEffect, useRef, useState } from 'react'
import './AccountComponent.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store/reducers'
import { SETTINGS } from '../../store/types'
import { Profile } from '../../store/models/profile'
import { useTranslation } from 'react-i18next'
import { logout } from '../../store/actions/auth.actions'
import { useHistory } from 'react-router'
import { Col, Row } from 'reactstrap'
import { fetchGet, fetchPost } from '../../store/rest/fetch'
import { validateEmail } from '../../helpers'
import { getProfile } from '../../store/actions/profile.actions'
import EmailBox from './EmailBox/EmailBox'
import Logout from './Logout/Logout'
import SettingLinksComponent from './SettingLinksComponent'

interface AccountComponentProps {
	profile: Profile | null
}

const AccountComponent: React.FC<AccountComponentProps> = ({ profile }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const dispatch = useDispatch()
	const router = useHistory()
	const [subscriptionStatus, setSubscriptionStatus] = useState(-1)
	const [subsExpires, setSubsExpires] = useState('')
	const [chngEmail, setChngEmail] = useState(false)
	useEffect(() => {
		fetchGet('profile/subscription')
			.then((res) => {
				if (res.status === 'ok') {
					setSubsExpires(new Date(res.data.subscription.expires_at).toLocaleDateString())
					setSubscriptionStatus((new Date(res.data.expires_at).getTime() - new Date().getTime()) / 86400000)
				} else {
					setSubsExpires('')
					setSubscriptionStatus(-1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [profile])
	useEffect(() => {
		chngEmail && changeInput && changeInput.current && changeInput.current.select()
	}, [chngEmail])
	const [currentEmail, setCurrentEmail] = useState('')
	const [currentEmailIsVal, setCurrentEmailIsVal] = useState(true)
	const [currentEmailIsFetching, setCurrentEmailIsFetching] = useState(false)
	const changeInput = useRef<HTMLInputElement>(null)

	const updateEmail = () => {
		if (currentEmailIsFetching) return
		setCurrentEmailIsFetching(true)
		currentEmailIsVal &&
			fetchPost({ email: currentEmail }, 'profile/update')
				.then((res) => {
					dispatch(getProfile())
					setChngEmail(false)
					setCurrentEmailIsFetching(false)
				})
				.catch((err) => {
					console.log(err)
					setCurrentEmailIsFetching(false)
				})
	}
	return (
		<div className="account">
			{isAuthenticated ? (
				<div className="mt-5 container-fluid profileHeader">
					<div
						className="container d-flex align-items-center"
						style={{ height: '100%', position: 'relative' }}
					>
						<Row style={{ width: '100%' }}>
							<EmailBox profile={profile} />
							{isAuthenticated && <Logout />}
						</Row>
					</div>
				</div>
			) : (
				<div className="container">
					<button
						className="col-12 col-md-3 loginBtn"
						onClick={() => {
							dispatch({ type: SETTINGS.LOGIN_POPUP, payload: true })
						}}
					>
						<i className="fas fa-sign-in-alt" style={{ marginRight: '0.7rem' }}></i>
						{t('account.logorreg')}
					</button>
				</div>
			)}
			<div className="container mt-4 profileSection">
				<div className="profileSection1 row ">
					<div className="col-12 col-md-8 d-flex flex-column profileTop">
						<div className="d-flex justify-content-between align-items-center profileTopInner">
							<div className="subscription">
								<span>{t('account.subscription')}</span>
							</div>
							<div className="activate">
								<span
									onClick={() => {
										router.push('/' + t('routes.account') + '/' + t('routes.subscription'))
									}}
								>
									{subsExpires ? t('account.subscriptionStatus') + subsExpires : t('account.activateSubscription')}
								</span>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 d-flex flex-column profileTop">
						<div className="profileTopInner d-flex justify-content-between align-items-center">
							<div className="subscription my-1">
								<span>{t('account.status')} </span>
							</div>
							<div className="subscriptionStatus my-1">{subscriptionStatus > 0 ? 'PREMIUM' : 'Free'}</div>
							{
								// (
								//   <div className="time my-1 d-flex flex-row">
								//     <div className="timeText">{t("days")}:</div>
								//     <div className="timeNumber">
								//       {subscriptionStatus.toFixed(0)}
								//     </div>
								//   </div>
								// )
							}
						</div>
					</div>
					{/* <div className="col-12 d-flex flex-row profileTop">
						<div
							className="profileTopInner profileNotif"
							style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
						>
							<i className="far fa-bell" style={{ marginTop: '0.2rem' }}></i>
							<div className="notiftitle" style={{ marginLeft: '0.8rem', fontWeight: 'bold' }}>
								{t('notifications')}
							</div>
						</div>
					</div> */}
					<SettingLinksComponent
						icon="fas fa-wallet"
						title={t('account.buy')}
						link={'/' + t('routes.account') + '/' + t('routes.purchased')}
					/>
					<SettingLinksComponent
						icon="far fa-bookmark"
						title={t('account.watchlater')}
						link={'/' + t('routes.account') + '/' + t('routes.watchLater')}
					/>
					<SettingLinksComponent
						icon="fas fa-history"
						title={t('account.watches')}
						link={'/' + t('routes.account') + '/' + t('routes.views')}
					/>
					<SettingLinksComponent
						icon="fas fa-cog"
						title={t('account.setting')}
						link={'/' + t('routes.account') + '/' + t('routes.settingsPage')}
					/>
					<SettingLinksComponent
						icon="fas fa-headset"
						title={t('account.support')}
						link={'/' + t('routes.contactus')}
					/>
				</div>
			</div>
		</div>
	)
}

export default AccountComponent
