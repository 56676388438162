import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal } from 'reactstrap'
import { SETTINGS } from '../../store/types'
import './SubscriptionComponent.scss'

interface SubscriptionComponentProps {}

const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({}) => {
	const { t } = useTranslation('azcinemaonline')
	const dispatch = useDispatch()
	const [subscriptionDuration, setSubscriptionDuration] = useState(2)
	const values = [1, 3, 6, 12]
	const prices = [4.99, 11.99, 21.99, 37.99]
	return (
		<Modal
			modalClassName="subscriptionModal"
			isOpen={true}
			toggle={() => {
				dispatch({
					type: SETTINGS.SUBSCRIPTION_POPUP,
					payload: false,
				})
			}}
			className="mt-2 wrapper mx-auto w-100 sign-up"
		>
			<div className="subscription-container">
				<div className="subscription-header ">
					<h2 className="text-center text-dark font-weight-bold">{t('subscription.status')}</h2>
					<h5 className="text-muted">{t('subscription.advice')}</h5>
					<div className="slidecontainer">
						<div className="d-flex flex-direction-row justify-content-between text-dark font-weight-bold">
							<p className="mb-n1"> 1 {t('subscription.month')}</p>
							<p className="mb-n1">12 {t('subscription.month')}</p>
						</div>
						<input
							type="range"
							min="0"
							max="3"
							className="slider"
							id="myRange"
							value={subscriptionDuration}
							onChange={(e) => setSubscriptionDuration(+e.target.value)}
						/>
						<div className="d-flex flex-direction-row justify-content-between text-muted small">
							<p className="mb-t1"> {t('subscription.from')} </p>
							<p className="mb-t1"> {t('subscription.to')} </p>
						</div>
					</div>
					<div className="col-12 text-dark">
						{values[subscriptionDuration]} month - {prices[subscriptionDuration]} {t('subscription.azn')}
					</div>
					<div className="row text-muted my-3">
						<div className="col-12 col-lg-7">
							<p>*{t('subscription.note')}</p>
						</div>
						<div className="col-12 col-lg-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<button type="button" className="text-white btn btn-warning" style={{ maxHeight: '40px' }}>
								{t('subscription.button')}
							</button>
						</div>
					</div>
					<h3>
						&nbsp;
						<i className="fas fa-wallet"></i>
						&nbsp; {t('subscription.history')}
					</h3>
				</div>

				<table className="table">
					<tbody>
						<tr>
							<th scope="row">{t('subscription.number')}</th>
							<td className="font-weight-bold">{t('subscription.name')}</td>
							<td className="font-weight-bold">{t('subscription.date')}</td>
							<td className="font-weight-bold">{t('subscription.price')}</td>
						</tr>
						<tr>
							<td>#925484658461</td>
							<td>Jacob</td>
							<td>21/12/2012</td>
							<td>10 $</td>
						</tr>
						<tr>
							<td>#925484658461</td>
							<td>Larry</td>
							<td>21/12/2012</td>
							<td>10 $</td>
						</tr>
						<tr>
							<td>#925484658461</td>
							<td>Larry</td>
							<td>21/12/2012</td>
							<td>10 $</td>
						</tr>
						<tr>
							<td>#925484658461</td>
							<td>Larry</td>
							<td>21/12/2012</td>
							<td>10 $</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
	)
}

export default SubscriptionComponent
