export const GENRES_REQUEST = 'GENRES_REQUEST'
export const GENRES_SUCCESS = 'GENRES_SUCCESS'
export const GENRES_FAILURE = 'GENRES_FAILURE'

export const MOVIES_REQUEST = 'MOVIES_REQUEST'
export const MOVIES_SUCCESS = 'MOVIES_SUCCESS'
export const MOVIES_FAILURE = 'MOVIES_FAILURE'

export const BLOG_REQUEST = 'MOVIE_REQUEST'
export const BLOG_SUCCESS = 'MOVIE_REQUEST'
export const BLOG_FAILURE = 'MOVIE_REQUEST'

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_ERROR_RESET = 'AUTH_ERROR_RESET'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'

export const SETTINGS = {
	LOGIN_POPUP: 'SETTINGS_LOGIN_POPUP',
	SIGNUP_POPUP: 'SETTINGS_SIGNUP_POPUP',
	SUBSCRIPTION_POPUP: 'SETTINGS_SUBSCRIPTION_POPUP',
}
