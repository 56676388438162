import React, { useEffect } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

import { useTranslation } from 'react-i18next'
import './Footer.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store/reducers'
import { fetchGenres } from '../../store/actions/genres.action'

const Footer: React.FC<RouteComponentProps> = (props) => {
	const { t , i18n } = useTranslation(['azcinemaonline'])
	const genres = useSelector((state: AppState) => state.genres.genres)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchGenres())
	}, [])
	return (
		<div className="Footer">
			<div className="topLine"></div>
			<Container fluid={true}>
				<Row>
					<Col sm={{ size: 10 }} xs={{ size: 12 }}>
						<Row>
							<Col sm={{ size: 5 }} xs={{ size: 12 }}>
								<Row className="footerTitle">
									<Col className="title">{t('footer.popularGenres')}</Col>
								</Row>
								<Row className="content">
									{genres.slice(0, 12).map((genre) => {
										return (
											<Col
												key={genre.id}
												className="content-element"
												sm={{ size: 6 }}
												xs={{ size: 4 }}
											>
												<Link
													to={'/' + t('routes.movies') + '/' + genre.slug}
													style={{ color: 'inherit', textDecoration: 'none' }}
												>
													{genre.name}
												</Link>
											</Col>
										)
									})}
								</Row>
							</Col>
							<Col sm={{ size: 4 }} xs={{ size: 12 }}>
								{/* <Row className="footerTitle">
									<Col className="title">{t('collection')}</Col>
								</Row>
								<Row className="content">
									<Col className="content-element" sm={{ size: 6 }} xs={{ size: 4 }}>
										<Link to="#" style={{ color: 'inherit', textDecoration: 'none' }}>
											{t('videos')}
										</Link>
									</Col>
									<Col className="content-element" sm={{ size: 6 }} xs={{ size: 4 }}>
										<Link to="#" style={{ color: 'inherit', textDecoration: 'none' }}>
											{t('videos')}
										</Link>
									</Col>
									<Col className="content-element" sm={{ size: 6 }} xs={{ size: 4 }}>
										<Link to="#" style={{ color: 'inherit', textDecoration: 'none' }}>
											{t('videos')}
										</Link>
									</Col>
									<Col className="content-element" sm={{ size: 6 }} xs={{ size: 4 }}>
										<Link to="#" style={{ color: 'inherit', textDecoration: 'none' }}>
											{t('videos')}
										</Link>
									</Col>
									<Col className="content-element" sm={{ size: 6 }} xs={{ size: 4 }}>
										<Link to="#" style={{ color: 'inherit', textDecoration: 'none' }}>
											{t('videos')}
										</Link>
									</Col>
								</Row> */}
							</Col>
							<Col sm={{ size: 3 }} xs={{ size: 12 }}>
								<Row className="footerTitle">
									<Col className="title">{t('footer.support')}</Col>
								</Row>
								<Row className="content">
									<Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes.aboutus')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('footer.about-us')}
										</Link>
									</Col>
									<Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes.terms')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('footer.terms')}
										</Link>
									</Col>
									<Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes.faq')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('footer.faq')}
										</Link>
									</Col>
									{/* <Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes:feedback')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('feedback')}
										</Link>
									</Col> */}
									<Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes.privacy')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('footer.privacy')}
										</Link>
									</Col>
									<Col sm={{ size: 8 }} xs={{ size: 3 }} className="content-element">
										<Link
											to={'/' + t('routes.contactus')}
											style={{ color: 'inherit', textDecoration: 'none' }}
										>
											{t('footer.contact-us')}
										</Link>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col sm={{ size: 2 }} xs={{ size: 12 }}>
						<Row className="footerTitle">
							<Col className="title">{t('footer.socials')}</Col>
						</Row>
						<Row className="content">
							<Col className="content-element" sm={{ size: 8 }} xs={{ size: 4 }}>
								<a
									href="https://www.facebook.com/azcinemaonline"
									target="_blank"
									rel="noreferrer"
									title="Az Cinema Online Facebook"
								>
									Facebook
								</a>
							</Col>
							<Col className="content-element" sm={{ size: 8 }} xs={{ size: 4 }}>
								<a
									href="https://www.instagram.com/azcinemaonline"
									target="_blank"
									rel="noreferrer"
									title="Az Cinema Online Instagram"
								>
									Instagram
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
				<div className="bottomLine"></div>
				<Row className="align-items-center menu pb-3">
					<Col xs={12} className="copyrightContainer">
						<div className="createdBy">
							Created by <a href="https://studio.gadimlie.com"
								target="_blank"
								className="gadimlielink text-decoration-none"
							>
								 Gadimlie Studio
							</a>
						</div>
						<div className="copyright"> {new Date().getFullYear()} © Narimanfilm</div>
					</Col>
					<div className="languageLine"></div>
					{/* <Col xs={12} md={5} lg={5} className="nav ml-auto infoPart Language">
						<li className="nav-item link">
							<Link
								to=""
								onClick={(e) => {
									e.preventDefault()
									window.location.pathname = 'az' + revealRoute('az')
								}}
								className="nav-link"
								style={{ color: 'inherit', textDecoration: 'none' }}
							>
								<span>Azərbaycan</span>
							</Link>
						</li>
						<li className="nav-item link">
							<Link
								to=""
								onClick={(e) => {
									e.preventDefault()
									window.location.pathname = 'en' + revealRoute('en')
								}}
								className="nav-link"
								style={{ color: 'inherit', textDecoration: 'none' }}
							>
								<span>English</span>
							</Link>
						</li>
						<li className="nav-item link">
							<Link
								to=""
								onClick={(e) => {
									e.preventDefault()
									window.location.pathname = 'ru' + revealRoute('ru')
								}}
								className="nav-link"
								style={{ color: 'inherit', textDecoration: 'none' }}
							>
								<span>Русский</span>
							</Link>
						</li>
					</Col> */}
				</Row>
			</Container>
			<a id="blankLink" target="_blank"></a>
		</div>
	)
}
export default withRouter(Footer)
