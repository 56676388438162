import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Progress } from 'reactstrap'
import '../CrowdfundingDetailComponent.scss'
import { Fundings } from '../../../store/models/fundings'
import { useProgressiveImage } from '../../../helpers/useProgressiveImage'
import config from '../../../config'
// @ts-ignore
import ReactJWPlayer from 'react-jw-player'
import { useHistory } from 'react-router'
import { ReactComponent as PlayButton } from '../../../assets/play-button(1).svg'
// @ts-ignore
// import MotherTrailer from 'https://studio.gadimlie.com/storage/app/media/trailer_motherland.mp4'



interface TrailerComponentProps {
  src: string 
  cover: string | null
}

const TrailerComponent: React.FC<TrailerComponentProps> = ({ src, cover }) => {
  const { t } = useTranslation(['azcinemaonline'])

  const trailer = config.media_url + encodeURI(src || '')


  const fullScreenButton = useRef<HTMLButtonElement>(null)
	const router = useHistory()

	const onReady = (event: any) => {
		// debugger
		// @ts-ignore
		const player = window.jwplayer('1234')
		player.setConfig({
			mute: true,
		})
		player.play()
		player.setControls(false)
		// @ts-ignore
		fullScreenButton.current.onclick = () => {
			player.setFullscreen(true)
		}
	}
	const onEnterFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('1234')
		player.setControls(true)
		player.setConfig({
			mute: false,
		})
	}
	const onExitFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('1234')
		player.setControls(false)
		player.setConfig({
			mute: true,
		})
	}

  return (
    <div className='TrailerComponent'>
        <div className="hot-movie-banner">

            <ReactJWPlayer
                className={'hot-movie'}
                playerId={'1234'}
                playerScript={config.player_url}
                file='https://studio.gadimlie.com/storage/app/media/trailer_motherland.mp4'
                onReady={onReady}
                onEnterFullScreen={onEnterFullScreen}
                onExitFullScreen={onExitFullScreen}
            />
            <div className="d-flex align-items-end hot-movie">
                <div className="d-flex justify-content-between align-items-center meta">
                    <div className="title">
                    </div>
                    <div className="d-flex align-items-center actions">
                        <button ref={fullScreenButton} className="d-md-inline mr-md-5 watch-trailer">
                            <span>{t('hotmovie.trailer')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TrailerComponent
